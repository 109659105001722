var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upload-image"},[(_vm.isUploadImageModalOpen)?_c('div',[_c('div',{staticClass:"ida-msgbox-content"},[_c('div',{staticClass:"filepond-container"},[_c('FilePond',{attrs:{"accepted-file-types":['image/png', 'image/jpeg', 'image/svg+xml'],"label-idle":_vm.$t('app.editor.filepond.label'),"max-file-size":"4MB","server":{
            process: {
              url: _vm.uploadApiUrl,
              onload: _vm.onResponse,
              headers: _vm.configAuthenticated.headers
            }
          }}}),_vm._v(" "+_vm._s(_vm.$t('app.editor.filepond.or'))+" "),_c('ida-input',{attrs:{"id":"image_url","type":"url","pattern":"https?:\\/\\/(www\\.)?.{2,256}","required":"","placeholder":_vm.$t('app.editor.filepond.input')},nativeOn:{"click":function($event){return (e => e.target.focus()).apply(null, arguments)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)}},model:{value:(_vm.imageUrl),callback:function ($$v) {_vm.imageUrl=$$v},expression:"imageUrl"}})],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }